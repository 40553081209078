/* eslint-disable import/named */
import requestConfig from 'config/requestExodus';

import { API_EXODUS_URL } from 'constants/api';
import { APIResponse } from '../../../@types/api-response';

const valuationURL = `${API_EXODUS_URL}/valuation`;

const request = requestConfig();

const getValuationTemplate = () => request.get(`${valuationURL}/all/templates`);

const getValuationMetrics = (): Promise<APIResponse> =>
  request.get(`${valuationURL}/metrics/lists`);

const postDeleteValuationTemplate = (valuationId) =>
  request.delete(`${valuationURL}/${valuationId}`);

const postValuationResult = ({ symbol, ...data }): Promise<APIResponse> =>
  request.post(`${valuationURL}/company/${symbol}`, data);

const postValuationTemplateSave = (templateId, data): Promise<APIResponse> =>
  request.put(`${valuationURL}/${templateId}`, data);

const postValuationTemplateAdd = (data): Promise<APIResponse> =>
  request.post(`${valuationURL}`, data);

const getValuationTemplateLoad = (templateId) =>
  request.get(`${valuationURL}/${templateId}`);

const getValuationMetricsDefault = ({ symbol, ...data }) =>
  request.get(`${valuationURL}/company/${symbol}/metrics`, {
    params: { ...data },
  });

const getValuationMetricPerID = (
  symbol: string,
  metricID: number,
): Promise<APIResponse> =>
  request.get(`${valuationURL}/company/${symbol}/metrics`, {
    params: {
      metric: metricID,
    },
  });

export default {
  getValuationTemplate,
  getValuationMetrics,
  postDeleteValuationTemplate,
  postValuationResult,
  postValuationTemplateSave,
  postValuationTemplateAdd,
  getValuationTemplateLoad,
  getValuationMetricsDefault,
  getValuationMetricPerID,
};
