const SUCCESS_MESSAGE = {
  ALERT_GREEN: 'alert-green',
  SNACKBAR_GREEN: 'snackbar-green',
  PROFILE_UPDATED: 'Profile Updated',
  POST_SUCCESSFUL: 'Post successful',
  REPOST_SUCCESSFUL: 'Post Reposted',
  REPLY_SUCCESSFUL: 'Post reply successful!',
  SET_TOP_SUCCESSFUL: 'Set as Top successful!',
  REMOVE_TOP_SUCCESSFUL: 'Remove as Top successful!',
  RESET_TOP_SUCCESSFUL: 'Reset as Top successful!',
  UNTAG_POST_SUCCESSFUL: 'Untag Post Topic Success',
  PASSWORD_CHANGED: 'Password changed',
  USER_BLOCKED: 'User blocked',
  USER_UNBLOCKED: 'User unblocked',
  CHANGE_NUMBER_SUCCESSFUL: 'Phone number changed!',
  SAVE_TEMPLATE_SUCCESS: 'Success save template',
  POST_SHARED_SUCCESSFUL: 'Post Shared',
  EVENT_SHARED_SUCCESSFUL: 'Event Shared',
  POST_PINNED_SUCCESSFUL: 'Post successfully pinned',
  POST_UNPINNED_SUCCESSFUL: 'Post successfully unpinned',
  POST_SUCCESSFUL_UPDATE: 'Post successfully updated',
  POST_SUCCESSFUL_DELETED: 'Successfully deleted',
  SCREENER_TEMPLATE_DELETED: 'Screener Template Deleted',
  FACEBOOK_CONNECTED: 'Facebook connect successful',
  GOPAY_CHANGED: 'Nomor gopay anda berhasil diubah',
  REPORT_DELIVERED: 'Report has been delivered',
  UPDATE_GROUP_PHOTO_SUCCESSFUL: 'Group Photo Updated',
  DELETE_STREAM_MULTIPLE_SUCCESSFUL: 'Success delete stream',
  FINANCIAL_COLUMN_DELETED: 'Financial Column Deleted',
};

export default SUCCESS_MESSAGE;
