import {
  IScreenerFavorites,
  IAddFavoriteScreener,
  IRemoveFavoriteScreener,
  IScreenerFinancial,
  IScreenerSaveOrRunPayload,
  IScreenerRemoveCustomScreenerResult,
  ScreenerTemplateType,
} from 'features/screener/types';
import { APIResponse } from '../../../@types/api-response';
import requestConfig from 'config/requestExodus';

import { API_EXODUS_URL } from 'constants/api';

const screenerURL = `${API_EXODUS_URL}/screener`;

const request = requestConfig();

const getScreenerCustom = (): Promise<APIResponse> =>
  request.get(`${screenerURL}/templates`);

const getScreenerMetrics = (): Promise<APIResponse> =>
  request.get(`${screenerURL}/preset`);

const getLoadPresetScreener = (id, type) => {
  const queryString = type ? `?type=${type}` : '';

  return request.get(`${screenerURL}/templates/${id}${queryString}`);
};

const getScreenerUniverse = () =>
  request.get(`${screenerURL}/universe`);

const postScreenerListResult = (data: IScreenerSaveOrRunPayload): Promise<APIResponse> =>
  request.post(`${screenerURL}/templates`, data);

const getScreenerFavorites = () =>
  request.get<IScreenerFavorites>(`${screenerURL}/favorites`);

const addScreenerFavorite = (screenerid: number, type: ScreenerTemplateType) =>
  request.post<IAddFavoriteScreener>(`${screenerURL}/favorites`, {
    screenerid,
    type,
  });

const removeScreenerFavorite = (screenerid: number, type: ScreenerTemplateType) =>
  request.delete<IRemoveFavoriteScreener>(`${screenerURL}/favorites/${screenerid}?type=${type}`);

const getScreenerFinancial = () =>
  request.get<IScreenerFinancial>(`${screenerURL}/metric`);

const removeScreenerCustom = (screenerID: number) =>
  request.delete<IScreenerRemoveCustomScreenerResult>(
    `${screenerURL}/templates/${screenerID}`,
  );

export default {
  getScreenerCustom,
  getScreenerMetrics,
  getLoadPresetScreener,
  getScreenerUniverse,
  postScreenerListResult,
  getScreenerFavorites,
  addScreenerFavorite,
  removeScreenerFavorite,
  getScreenerFinancial,
  removeScreenerCustom,
};
