import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import handleErrorMessageAPI from 'global/AlertErrorMessage';
import SUCCESS_MESSAGE from 'constants/successMessage';
import valuationAPI from 'lib/api/valuation';
import searchAPI from 'lib/api/search';
import {
  ICompanySymbol,
  IDefaultMetricDetail,
  IValuationPerTypes,
  IValuationMetricOptions,
  IValuationResult,
  IValuationSaveTemplateResult,
  IMetric,
  IValuationLoadDetailTemplate,
  IValuationUpdateTemplateResult,
  IValuationDeleteTemplateResult,
} from './interfaces';

// action types
const CONTEXT = '@feature/valuation';

const actionType = {
  GET_VALUATION_METRICS: `${CONTEXT}/GET_VALUATION_METRICS`,
  GET_COMPANY_SYMBOL: `${CONTEXT}/GET_COMPANY_SYMBOL`,
  GET_DEFAULT_VALUATION_METRIC: `${CONTEXT}/GET_DEFAULT_VALUATION_METRIC`,
  GET_VALUATION_EPS: `${CONTEXT}/GET_VALUATION_EPS`,
  GET_VALUATION_GROWTH: `${CONTEXT}/GET_VALUATION_GROWTH`,
  GET_VALUATION_MULTIPLE: `${CONTEXT}/GET_VALUATION_MULTIPLE`,
  RUN_VALUATION: `${CONTEXT}/RUN_VALUATION`,
  CREATE_VALUATION_TEMPLATE: `${CONTEXT}/CREATE_VALUATION_TEMPLATE`,
  GET_VALUATION_TEMPLATE_DETAIL: `${CONTEXT}/GET_VALUATION_TEMPLATE_DETAIL`,
  UPDATE_VALUATION_TEMPLATE_DETAIL: `${CONTEXT}/UPDATE_VALUATION_TEMPLATE_DETAIL`,
  DELETE_VALUATION_TEMPLATE_DETAIL: `${CONTEXT}/DELETE_VALUATION_TEMPLATE_DETAIL`,
};

interface IValuationState {
  valuationSymbol: { symbol: string; id: string };
  valuationMetricOptions: IValuationMetricOptions;
  companySymbol: ICompanySymbol;
  valuationDefaultMetric: IDefaultMetricDetail;
  valuationEPS: IValuationPerTypes;
  valuationGROWTH: IValuationPerTypes;
  valuationMULTIPLE: IValuationPerTypes;
  valuationResult: IValuationResult;
  valuationCreateTemplateResult: IValuationSaveTemplateResult;
  valuationUpdateTemplateResult: IValuationUpdateTemplateResult;
  valuationDeleteTemplateResult: IValuationDeleteTemplateResult;
  valuationTemplateDetail: IValuationLoadDetailTemplate;
  metric: IMetric;
  note: string;
  isMobileView: boolean;
  valuationMetricKeyword: string;
}

const initialState: IValuationState = {
  valuationSymbol: {
    id: '',
    symbol: '',
  },
  valuationMetricOptions: {
    message: '',
    data: {},
    isError: false,
    isLoading: false,
  },
  valuationEPS: {
    message: '',
    data: [],
    isError: false,
    isLoading: false,
  },
  valuationGROWTH: {
    message: '',
    data: [],
    isError: false,
    isLoading: false,
  },
  valuationMULTIPLE: {
    message: '',
    data: [],
    isError: false,
    isLoading: false,
  },
  valuationDefaultMetric: {
    message: '',
    data: [],
    isError: false,
    isLoading: false,
  },
  valuationResult: {
    data: {
      consensus_high: '',
      consensus_low: '',
      consensus_medium: '',
      country: '',
      current_price: '',
      exchange: '',
      margin_safety: '',
      symbol: '',
      symbol_2: '',
      symbol_3: '',
      target_price: '',
    },
    message: '',
    isError: false,
    isLoading: false,
  },
  valuationCreateTemplateResult: {
    message: '',
    isError: false,
    isLoading: false,
    data: {},
  },
  valuationUpdateTemplateResult: {
    message: '',
    isError: false,
    isLoading: false,
    data: [],
  },
  valuationDeleteTemplateResult: {
    message: '',
    isError: false,
    isLoading: false,
    data: [],
  },
  valuationTemplateDetail: {
    message: '',
    isError: false,
    isLoading: false,
    data: {},
  },
  companySymbol: {
    data: {},
    isError: false,
    isLoading: false,
    message: '',
  },
  metric: {
    comp_id: '',
    item: [],
  },
  note: '',
  isMobileView: false,
  valuationMetricKeyword: '',
};

const valuationState = (state) => state.valuation;
export const valuationSelectors = createSelector(
  valuationState,
  (valuation: IValuationState) => ({
    valuationSymbol: valuation.valuationSymbol,
    valuationMetricOptions: valuation.valuationMetricOptions,
    valuationDefaultMetric: valuation.valuationDefaultMetric,
    valuationEPS: valuation.valuationEPS,
    valuationGROWTH: valuation.valuationGROWTH,
    valuationMULTIPLE: valuation.valuationMULTIPLE,
    valuationResult: valuation.valuationResult,
    valuationCreateTemplateResult: valuation.valuationCreateTemplateResult,
    valuationUpdateTemplateResult: valuation.valuationUpdateTemplateResult,
    valuationDeleteTemplateResult: valuation.valuationDeleteTemplateResult,
    valuationDetail: valuation.valuationTemplateDetail,
    companySymbols: valuation.companySymbol,
    metric: valuation.metric,
    note: valuation.note,
    isMobileView: valuation.isMobileView,
    valuationMetricKeyword: valuation.valuationMetricKeyword,
  }),
);

export const valuationEffects = {
  getValuationMetrics: createAsyncThunk<any, void>(
    actionType.GET_VALUATION_METRICS,
    async () => {
      try {
        const response = await valuationAPI.getValuationMetrics();
        if (!response.data) {
          throw new Error('Attempt to get valuation metrics failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getCompanySymbol: createAsyncThunk<any, string>(
    actionType.GET_COMPANY_SYMBOL,
    async (query) => {
      try {
        const response = await searchAPI.getSearchCompany(query);
        if (!response.data) {
          throw new Error('Attempt to get company symbol is failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getDefaultValuationMetric: createAsyncThunk<any, { symbol: string }>(
    actionType.GET_DEFAULT_VALUATION_METRIC,
    async ({ symbol }) => {
      try {
        const response = await valuationAPI.getValuationMetricsDefault({
          symbol,
        });
        if (!response.data) {
          throw new Error('Attempt to get default metric is failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getValuationEPS: createAsyncThunk<any, { symbol: string; metricID: number }>(
    actionType.GET_VALUATION_EPS,
    async ({ symbol, metricID }) => {
      try {
        const response = await valuationAPI.getValuationMetricPerID(
          symbol,
          metricID,
        );
        if (!response.data) {
          throw new Error('Attempt to get metric EPS is failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  getValuationGROWTH: createAsyncThunk<
    any,
    { symbol: string; metricID: number }
  >(actionType.GET_VALUATION_GROWTH, async ({ symbol, metricID }) => {
    try {
      const response = await valuationAPI.getValuationMetricPerID(
        symbol,
        metricID,
      );
      if (!response.data) {
        throw new Error('Attempt to get metric GROWTH is failed');
      }
      const { data } = response;
      return data;
    } catch (error) {
      return { error };
    }
  }),
  getValuationMULTIPLE: createAsyncThunk<
    any,
    { symbol: string; metricID: number }
  >(actionType.GET_VALUATION_MULTIPLE, async ({ symbol, metricID }) => {
    try {
      const response = await valuationAPI.getValuationMetricPerID(
        symbol,
        metricID,
      );
      if (!response.data) {
        throw new Error('Attempt to get metric MULTIPLE is failed');
      }
      const { data } = response;
      return data;
    } catch (error) {
      return { error };
    }
  }),
  createValuation: createAsyncThunk<
    any,
    {
      symbol: string;
      eps_value: string;
      growth_value: string;
      multiple_value: string;
    }
  >(
    actionType.RUN_VALUATION,
    async ({ symbol, eps_value, growth_value, multiple_value }) => {
      try {
        const response = await valuationAPI.postValuationResult({
          symbol,
          eps_value,
          growth_value,
          multiple_value,
        });

        if (!response.data) {
          throw new Error('Attempt to create valuation is failed');
        }

        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  createValuationTemplete: createAsyncThunk<
    any,
    {
      payload: any;
    }
  >(actionType.CREATE_VALUATION_TEMPLATE, async ({ payload }) => {
    try {
      const response = await valuationAPI.postValuationTemplateAdd(payload);
      if (!response.data) {
        throw new Error('Attempt to create valuation template is failed');
      }
      const { data } = response;

      handleErrorMessageAPI(data.message, SUCCESS_MESSAGE.ALERT_GREEN);

      return data;
    } catch (error) {
      return { error };
    }
  }),
  getValuationDetailTemplate: createAsyncThunk<any, number>(
    actionType.GET_VALUATION_TEMPLATE_DETAIL,
    async (templateID) => {
      try {
        const response = await valuationAPI.getValuationTemplateLoad(
          templateID,
        );
        if (!response.data) {
          throw new Error('Attempt to get valuation detail is failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  updateValuationDetailTemplate: createAsyncThunk<
    any,
    {
      payload: any;
      templateID: string | number;
    }
  >(
    actionType.UPDATE_VALUATION_TEMPLATE_DETAIL,
    async ({ payload, templateID }) => {
      try {
        const response = await valuationAPI.postValuationTemplateSave(
          templateID,
          payload,
        );

        if (!response.data) {
          throw new Error('Attempt to update valuation detail is failed');
        }

        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
  deleteValuationDetailTemplate: createAsyncThunk<any, number>(
    actionType.DELETE_VALUATION_TEMPLATE_DETAIL,
    async (templateID) => {
      try {
        const response = await valuationAPI.postDeleteValuationTemplate(
          templateID,
        );
        if (!response.data) {
          throw new Error('Attempt to delete valuation detail is failed');
        }
        const { data } = response;
        return data;
      } catch (error) {
        return { error };
      }
    },
  ),
};

const reducers = {
  setValuationSymbol: (
    state: IValuationState,
    action: PayloadAction<{ symbol: string; id: string }>,
  ) => {
    const { id, symbol } = action.payload;
    state.valuationSymbol = {
      ...state.valuationSymbol,
      id,
      symbol,
    };
  },
  setValuationMetric: (
    state: IValuationState,
    action: PayloadAction<IMetric>,
  ) => {
    const { comp_id: compID, item } = action.payload;

    state.metric = {
      ...state.metric,
      comp_id: compID,
      item,
    };
  },
  setValuationNote: (state: IValuationState, action: PayloadAction<string>) => {
    state.note = action.payload;
  },
  setValuationResult: (state: IValuationState, action: PayloadAction<any>) => {
    state.valuationResult = {
      ...state.valuationResult,
      data: {
        ...state.valuationResult.data,
        ...action.payload,
      },
      isError: false,
      isLoading: false,
      message: '',
    };
  },
  resetValuationSymbol: (state: IValuationState) => {
    state.valuationSymbol = {
      id: '',
      symbol: '',
    };
  },
  resetValuationResult: (state: IValuationState) => {
    state.valuationResult = {
      ...state.valuationResult,
      data: {
        ...state.valuationResult.data,
        consensus_high: '',
        consensus_low: '',
        consensus_medium: '',
        country: '',
        current_price: '',
        exchange: '',
        margin_safety: '',
        symbol: '',
        symbol_2: '',
        symbol_3: '',
        target_price: '',
      },
      isError: false,
      isLoading: false,
      message: '',
    };
  },

  resetValuationDetail: (state: IValuationState) => {
    state.valuationTemplateDetail = {
      ...state.valuationTemplateDetail,
      data: {},
      isError: false,
      isLoading: false,
      message: '',
    };
  },

  resetTemplateResult: (state: IValuationState) => {
    state.valuationCreateTemplateResult = {
      ...state.valuationCreateTemplateResult,
      data: {},
      isError: false,
      isLoading: false,
      message: '',
    };
  },
  setIsMobileView: (state: IValuationState, action: PayloadAction<boolean>) => {
    state.isMobileView = action.payload;
  },
  setValuationMetricKeyword: (
    state: IValuationState,
    action: PayloadAction<string>,
  ) => {
    state.valuationMetricKeyword = action.payload;
  },
};

const extraReducer = (builder) => {
  builder
    .addCase(
      valuationEffects.getValuationMetrics.fulfilled,
      (
        state: IValuationState,
        action: PayloadAction<IValuationMetricOptions>,
      ) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationMetricOptions.isError = isError;
          state.valuationMetricOptions.message = message;
        } else {
          state.valuationMetricOptions.data = {
            ...state.valuationMetricOptions.data,
            ...data,
          };
          state.valuationMetricOptions.message = message;
        }

        state.valuationMetricOptions.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getValuationMetrics.pending,
      (state: IValuationState) => {
        state.valuationMetricOptions.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.getValuationMetrics.rejected,
      (
        state: IValuationState,
        action: PayloadAction<IValuationMetricOptions>,
      ) => {
        state.valuationMetricOptions.isError = action.payload.isError;
        state.valuationMetricOptions.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getCompanySymbol.fulfilled,
      (state: IValuationState, action: PayloadAction<ICompanySymbol>) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.companySymbol.isError = isError;
          state.companySymbol.message = message;
        } else {
          state.companySymbol.data = {
            ...state.companySymbol.data,
            ...data,
          };
          state.companySymbol.message = message;
        }

        state.companySymbol.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getCompanySymbol.pending,
      (state: IValuationState) => {
        state.companySymbol.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.getCompanySymbol.rejected,
      (state: IValuationState, action: PayloadAction<ICompanySymbol>) => {
        state.companySymbol.isError = action.payload.isError;
        state.companySymbol.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getDefaultValuationMetric.fulfilled,
      (state: IValuationState, action: PayloadAction<IDefaultMetricDetail>) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationDefaultMetric.isError = isError;
          state.valuationDefaultMetric.message = message;
        } else {
          state.valuationDefaultMetric.data = data;
          state.valuationDefaultMetric.message = message;
        }

        state.valuationDefaultMetric.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getDefaultValuationMetric.pending,
      (state: IValuationState) => {
        state.valuationDefaultMetric.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.getDefaultValuationMetric.rejected,
      (state: IValuationState, action: PayloadAction<IDefaultMetricDetail>) => {
        state.valuationDefaultMetric.isError = action.payload.isError;
        state.valuationDefaultMetric.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getValuationEPS.fulfilled,
      (state: IValuationState, action: PayloadAction<IValuationPerTypes>) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationEPS.isError = isError;
          state.valuationEPS.message = message;
        } else {
          state.valuationEPS.data = data;
          state.valuationEPS.message = message;
        }

        state.valuationEPS.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getValuationEPS.pending,
      (state: IValuationState) => {
        state.valuationEPS.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.getValuationEPS.rejected,
      (state: IValuationState, action: PayloadAction<IValuationPerTypes>) => {
        state.valuationEPS.isError = action.payload.isError;
        state.valuationEPS.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getValuationGROWTH.fulfilled,
      (state: IValuationState, action: PayloadAction<IValuationPerTypes>) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationGROWTH.isError = isError;
          state.valuationGROWTH.message = message;
        } else {
          state.valuationGROWTH.data = data;
          state.valuationGROWTH.message = message;
        }

        state.valuationGROWTH.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getValuationGROWTH.pending,
      (state: IValuationState) => {
        state.valuationGROWTH.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.getValuationGROWTH.rejected,
      (state: IValuationState, action: PayloadAction<IValuationPerTypes>) => {
        state.valuationGROWTH.isError = action.payload.isError;
        state.valuationGROWTH.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getValuationMULTIPLE.fulfilled,
      (state: IValuationState, action: PayloadAction<IValuationPerTypes>) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationMULTIPLE.isError = isError;
          state.valuationMULTIPLE.message = message;
        } else {
          state.valuationMULTIPLE.data = data;
          state.valuationMULTIPLE.message = message;
        }

        state.valuationMULTIPLE.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getValuationMULTIPLE.pending,
      (state: IValuationState) => {
        state.valuationMULTIPLE.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.getValuationMULTIPLE.rejected,
      (state: IValuationState, action: PayloadAction<IValuationPerTypes>) => {
        state.valuationMULTIPLE.isError = action.payload.isError;
        state.valuationMULTIPLE.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.createValuation.fulfilled,
      (state: IValuationState, action: PayloadAction<IValuationResult>) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationResult.isError = isError;
          state.valuationResult.message = message;
        } else {
          state.valuationResult.data = {
            ...state.valuationResult.data,
            ...data,
          };
          state.valuationResult.message = message;
        }

        state.valuationResult.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.createValuation.pending,
      (state: IValuationState) => {
        state.valuationResult.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.createValuation.rejected,
      (state: IValuationState, action: PayloadAction<IValuationResult>) => {
        state.valuationResult.isError = action.payload.isError;
        state.valuationResult.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.createValuationTemplete.fulfilled,
      (
        state: IValuationState,
        action: PayloadAction<IValuationSaveTemplateResult>,
      ) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationCreateTemplateResult.isError = isError;
          state.valuationCreateTemplateResult.message = message;
        } else {
          state.valuationCreateTemplateResult.data = {
            ...state.valuationCreateTemplateResult.data,
            ...data,
          };
          state.valuationCreateTemplateResult.message = message;
          valuationEffects.getValuationDetailTemplate(data.id);
        }

        state.valuationCreateTemplateResult.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.createValuationTemplete.pending,
      (state: IValuationState) => {
        state.valuationCreateTemplateResult.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.createValuationTemplete.rejected,
      (
        state: IValuationState,
        action: PayloadAction<IValuationSaveTemplateResult>,
      ) => {
        state.valuationCreateTemplateResult.isError = action.payload.isError;
        state.valuationCreateTemplateResult.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getValuationDetailTemplate.fulfilled,
      (
        state: IValuationState,
        action: PayloadAction<IValuationLoadDetailTemplate>,
      ) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationTemplateDetail.isError = isError;
          state.valuationTemplateDetail.message = message;
        } else {
          state.valuationTemplateDetail.data = {
            ...state.valuationTemplateDetail.data,
            ...data,
          };
          state.valuationTemplateDetail.message = message;
        }

        state.valuationTemplateDetail.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.getValuationDetailTemplate.pending,
      (state: IValuationState) => {
        state.valuationTemplateDetail.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.getValuationDetailTemplate.rejected,
      (
        state: IValuationState,
        action: PayloadAction<IValuationLoadDetailTemplate>,
      ) => {
        state.valuationTemplateDetail.isError = action.payload.isError;
        state.valuationTemplateDetail.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.updateValuationDetailTemplate.fulfilled,
      (
        state: IValuationState,
        action: PayloadAction<IValuationUpdateTemplateResult>,
      ) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationUpdateTemplateResult.isError = isError;
          state.valuationUpdateTemplateResult.message = message;
        } else {
          state.valuationUpdateTemplateResult.data = data;
          state.valuationUpdateTemplateResult.message = message;
        }

        state.valuationUpdateTemplateResult.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.updateValuationDetailTemplate.pending,
      (state: IValuationState) => {
        state.valuationUpdateTemplateResult.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.updateValuationDetailTemplate.rejected,
      (
        state: IValuationState,
        action: PayloadAction<IValuationUpdateTemplateResult>,
      ) => {
        state.valuationUpdateTemplateResult.isError = action.payload.isError;
        state.valuationUpdateTemplateResult.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.deleteValuationDetailTemplate.fulfilled,
      (
        state: IValuationState,
        action: PayloadAction<IValuationDeleteTemplateResult>,
      ) => {
        const { message, data, isError } = action.payload;

        if (isError) {
          state.valuationDeleteTemplateResult.isError = isError;
          state.valuationDeleteTemplateResult.message = message;
        } else {
          state.valuationDeleteTemplateResult.data = data;
          state.valuationDeleteTemplateResult.message = message;
        }

        state.valuationDeleteTemplateResult.isLoading = false;
      },
    )
    .addCase(
      valuationEffects.deleteValuationDetailTemplate.pending,
      (state: IValuationState) => {
        state.valuationDeleteTemplateResult.isLoading = true;
      },
    )
    .addCase(
      valuationEffects.deleteValuationDetailTemplate.rejected,
      (
        state: IValuationState,
        action: PayloadAction<IValuationDeleteTemplateResult>,
      ) => {
        state.valuationDeleteTemplateResult.isError = action.payload.isError;
        state.valuationDeleteTemplateResult.isLoading = false;
      },
    );
};

const valuationSlice = createSlice({
  name: 'valuation',
  initialState,
  // for non-async reducer logic
  reducers,
  // for async reducer logic
  extraReducers: extraReducer,
});

export default valuationSlice;
