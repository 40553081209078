import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_EXODUS_URL, CUSTOM_USER_AGENT } from 'constants/api';
import { isClient } from 'constants/app';
import ERROR_MESSAGE from 'constants/errorMessage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import auth from 'utils/auth';
import { sentryLog } from 'utils/logger';
import generateNewRequestWithRefreshToken from 'config/refreshToken';
import {
  getRefreshTokenConfigFn,
  getAccessTokenDataFn,
  refreshExodusToken,
} from 'config/refreshToken/exodus';
import { dispatchSetErrorCode } from './APIErrorMessage/utils';

import handler from './APIErrorMessage';

const { APIExodusErrorHandler } = handler;

function requestExodus(
  isShowAlertMessage = true,
  context = null,
  customUserAgent = false,
  extraHeaders = {},
) {
  let headers: Record<string, any> = {
    Accept: 'application/json',
    ...extraHeaders,
  };

  if (customUserAgent) {
    headers = { ...headers, 'User-Agent': CUSTOM_USER_AGENT };
  }
  const apiInstance = axios.create({
    baseURL: API_EXODUS_URL,
    headers,
  });

  apiInstance.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      const requestFromBrowser = isClient();
      if (!requestFromBrowser) {
        return request;
      }

      if (!window?.navigator?.onLine) {
        handleErrorMessageAPI(ERROR_MESSAGE.OFFLINE, ERROR_MESSAGE.ALERT_RED);
        return Promise.reject(ERROR_MESSAGE.OFFLINE);
      }

      // Custom Authorization Header Exist
      if (request.headers.Authorization) {
        return request;
      }

      // Default Authorization
      const parsedToken = auth.getToken();
      const token = parsedToken?.accessToken?.token;
      if (request.url !== '/user/setting/password/reset' && token) {
        // eslint-disable-next-line no-param-reassign
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    },
    (error: AxiosError) => Promise.reject(error),
  );

  apiInstance.interceptors.response.use(
    async (
      response: AxiosResponse<{
        data: any;
        message: string;
        error_type?: string;
      }>,
    ) => {
      const responseRetrieveFromBrowser = isClient();
      if (!responseRetrieveFromBrowser) {
        return response;
      }

      //-------------------------------------------
      /**
       * @deprecated
       * Error Handler for 2xx Success Response
       * Delete if there is no 2xx error_type response anymore!
       */
      const {
        error_type: errorType,
        message = ERROR_MESSAGE.DEFAULT_ERROR_MESSAGE,
      } = response.data;

      // Some 200 API response could contain data.error_type
      if (errorType && isShowAlertMessage) {
        handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
        throw message;
      }
      //-------------------------------------------

      return response;
    },
    async (axiosError: AxiosError<{ message: string; error_type: string }>) => {
      const responseRetrieveFromBrowser = isClient();
      if (!responseRetrieveFromBrowser) {
        return Promise.reject(axiosError);
      }

      if (!axiosError?.response) {
        return Promise.reject(axiosError);
      }

      //-------------------------------------------
      // Error Logger
      sentryLog({
        scope: 'server-layer',
        level: 'info',
        httpStatusCode: axiosError.response?.status,
        tags: [
          { title: 'api-error-url', value: axiosError?.request?.responseURL },
          { title: 'api-error-text', value: axiosError?.request?.responseText },
        ],
        error: new Error(axiosError.message),
      });
      //-------------------------------------------

      //-------------------------------------------
      // Error Handler for Refresh Token
      if ([401].includes(axiosError.response?.status)) {
        try {
          const newRequest = await generateNewRequestWithRefreshToken({
            apiInstance,
            axiosError,
            getRefreshTokenConfig: getRefreshTokenConfigFn,
            getAccessTokenData: getAccessTokenDataFn,
            refreshTokenPromise: refreshExodusToken,
          });
          return newRequest;
        } catch (err) {
          dispatchSetErrorCode(401);
          return Promise.reject(err);
        }
      }
      //-------------------------------------------

      //-------------------------------------------
      // Error Handler Based on Status
      const { status, data } = axiosError.response;
      let message = data?.message;
      let errorType = data?.error_type;
      // Some error message is unparsed in `message`
      if (!errorType) {
        const parsedMessage = JSON.parse(message);
        message = parsedMessage.message;
        errorType = parsedMessage.error_type;
      }
      if (!isShowAlertMessage) {
        return Promise.reject(
          new Error(axiosError.message, {
            cause: { status, message, errorType },
          }),
        );
      }
      APIExodusErrorHandler(status, errorType, message);
      return Promise.reject(
        new Error(axiosError.message, {
          cause: { status, message, errorType },
        }),
      );
      //-------------------------------------------
    },
  );

  return apiInstance;
}

export default requestExodus;
