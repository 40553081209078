import * as Sentry from '@sentry/nextjs';
import auth from './auth';
import { capitalizer } from './general';

interface iLogOptions {
  /**
   * server-layer: related to SSR, API call, websocket, etc
   * data-layer: related to redux, zustand, hooks, etc
   * component-layer: related to component, styling, etc
   */
  scope: 'server-layer' | 'data-layer' | 'component-layer';
  level: 'error' | 'warning' | 'info' | 'fatal';
  username?: string;
  httpStatusCode?: number;
  error?: string | Error;
  tags?: { title: string; value: any }[];
}

export const setUserId = (userId: string | number | null) => {
  if (typeof window === 'undefined') return;

  const logger = window?.NREUM;
  if (!logger || !logger?.setUserId) return;

  if (userId !== null) logger?.setUserId(userId.toString());
};

export const noticeError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err?: any,
  customAttr?: Record<string, unknown>,
) => {
  if (typeof window === 'undefined') return;

  const logger = window?.NREUM;
  if (!logger) return;

  // Get User Id from localStorage
  const { id: userId } = (auth.getUserAccess() as { id?: number }) || {};

  // Set New Relic User Id
  if (err) {
    setUserId(userId || null);
    let mappedCustomAttr;
    try {
      mappedCustomAttr = Object.keys(customAttr).reduce((a, key) => {
        const newKey = key.startsWith('sb')
          ? capitalizer(key, 2)
          : `sb${capitalizer(key, 0)}`;
        return { ...a, [newKey]: customAttr[key] };
      }, {});
    } catch (error) {
      mappedCustomAttr = customAttr;
    }
    logger.noticeError(err, mappedCustomAttr);
  }
};

export default noticeError;

export const sentryLog = (options: iLogOptions) => {
  const { error, scope: scopeName, level, username, httpStatusCode, tags } = options;

  Sentry.withScope((scope) => {
    scope.setTag('scope', scopeName);
    scope.setLevel(level);
    if (httpStatusCode) {
      scope.setTag('http-status-code', httpStatusCode);
    }
    if (Array.isArray(tags) && tags.length > 0) {
      tags.forEach((item) => {
        scope.setTag(item.title, item.value);
      });
    }
    if (username) {
      scope.setUser({ username });
    }
    if (typeof error === 'string') {
      Sentry.captureMessage(error);
    }
    if (error instanceof Error) {
      Sentry.captureException(error);
    }
  });
};

export const sentrySetUser = (isLoggedIn: boolean) => {
  if (!isLoggedIn) {
    return;
  }
  const userData = auth.getUserAccess();
  Sentry.setUser({
    username: userData?.username,
    id: userData?.id,
  });
};
