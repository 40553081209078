import styled from "styled-components";
import color from "../_style/color";

const IconWrapper = styled.div.attrs((props) => ({
  size: props.size || "auto",
  padding: props.padding || "0px",
  newItem: props.newItem || 0,
  justify: props.justify || "center",
}))`
  position: relative;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  padding: ${({ padding }) => padding};
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  cursor : pointer;
  
  ::before {
    content: '${({ newItem }) => newItem || ""}';
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 7%;
    left: 7%;
    color: ${color.light};
    background: ${color.primary};
    border-radius: 50%;
    min-width: 18px;
    font-size: 12px;
    font-weight: bold;
    z-index: 2;
  }
`;

const Iconmoon = styled.p.attrs((props) => ({
  size: props.size || "16px",
  color: props.color || color.highgray,
  isActive: props.isActive || false,
  hoverColor: props.hoverColor || color.primary,
  weight: props.weight || "normal",
}))`
  font-family: "icomoon";
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  color: ${(props) => (!props.isActive ? props.color : color.primary)};
  margin: 0;

  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

export { IconWrapper };
export default Iconmoon;
