import {
  createAsyncThunk,
  createSlice,
  createSelector,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';

import { IScreenerSaveOrRunPayload, ScreenerTemplateType } from 'features/screener/types';

// Related Modules
import valuationAPI from 'lib/api/valuation';
import screenerAPI from 'lib/api/screener';

// Async Thunk Types
import {
  IValuationState,
  GetValuationMetricsResponse,
  GetValuationTemplateLoadResponse,
  GetValuationTemplateResponse,
  GetValuationMetricsDefaultResponse,
  PostDeleteValuationTemplateResponse,
  PostValuationTemplateAddResponse,
  PostValuationTemplateSaveResponse,
  PostValuationResultResponse,
  GetScreenerMetricsResponse,
  GetScreenerCustomResponse,
  GetLoadPresetScreenerResponse,
  PostScreenerListResultResponse,
} from './slice.types';
import { INITIAL_PAGE, KENNETH_FISHER_PRICE_TO_SALES_ID } from './constants';

// Actions
const CONTEXT = '@side-widget/valuation';

const actionType = {
  // valuation
  GET_VALUATION_TEMPLATE: `${CONTEXT}/GET_VALUATION_TEMPLATE`,
  GET_VALUATION_METRICS: `${CONTEXT}/GET_VALUATION_METRICS`,
  POST_DELETE_VALUATION_TEMPLATE: `${CONTEXT}/POST_DELETE_VALUATION_TEMPLATE`,

  POST_VALUATION_RESULT: `${CONTEXT}/POST_VALUATION_RESULT`,
  POST_VALUATION_TEMPLATE_SAVE: `${CONTEXT}/POST_VALUATION_TEMPLATE_SAVE`,
  POST_VALUATION_TEMPLATE_ADD: `${CONTEXT}/POST_VALUATION_TEMPLATE_ADD`,

  GET_VALUATION_TEMPLATE_LOAD: `${CONTEXT}/GET_VALUATION_TEMPLATE_LOAD`,

  GET_VALUATION_METRICS_DEFAULT: `${CONTEXT}/GET_VALUATION_METRICS_DEFAULT`,
  // screener
  GET_SCREENER_CUSTOM: `${CONTEXT}/GET_SCREENER_CUSTOM`,
  GET_SCREENER_METRICS: `${CONTEXT}/GET_SCREENER_METRICS`,
  GET_LOAD_PRESET_SCREENER: `${CONTEXT}/GET_LOAD_PRESET_SCREENER`,
  POST_SCREENER_LIST_RESULT: `${CONTEXT}/POST_SCREENER_LIST_RESULT`,
};

// initial state
const initialState: IValuationState = {
  // valuation
  getValuationTemplate: {
    data: [],
    isLoading: true,
    error: null,
  },
  getValuationMetrics: {
    data: {},
    isLoading: true,
    error: null,
  },
  getValuationTemplateLoad: {
    data: {},
    isLoading: false,
    error: null,
  },
  getValuationMetricsDefault: {
    data: [],
    isLoading: true,
    error: null,
  },
  postDeleteValuationTemplate: {
    data: [],
    isLoading: false,
    error: null,
    isSubmitted: false,
  },
  postValuationResult: {
    data: [],
    isLoading: false,
    error: null,
    isSubmitted: false,
  },
  postValuationTemplateSave: {
    data: [],
    isLoading: false,
    error: null,
    isSubmitted: false,
  },
  postValuationTemplateAdd: {
    data: {},
    isLoading: false,
    error: null,
    isSubmitted: false,
  },
  // screener
  getScreenerCustom: {
    data: [],
    isLoading: true,
    error: null,
  },
  getScreenerMetrics: {
    data: [],
    isLoading: true,
    error: null,
  },
  getLoadPresetScreener: {
    data: {},
    isLoading: true,
    error: null,
  },
  postScreenerListResult: {
    data: [],
    isLoading: false,
    error: null,
    isSubmitted: false,
  },
  // TODO: Remove this lines after layout feature has been implemented
  currentScreenerData: {
    page: INITIAL_PAGE,
    presetId: KENNETH_FISHER_PRICE_TO_SALES_ID,
    isDataLoaded: false,
    shouldUpdate: false,
    type: ''
  }
};

type ValuationState = typeof initialState;

// Selectors
const valuationState = (state: any) => state.mainLayout.valuation;
export const selectors = createSelector(
  valuationState,
  (state: ValuationState) => ({
    // valuation
    getValuationTemplate: state.getValuationTemplate,
    getValuationMetrics: state.getValuationMetrics,
    getValuationTemplateLoad: state.getValuationTemplateLoad,
    getValuationMetricsDefault: state.getValuationMetricsDefault,
    postDeleteValuationTemplate: state.postDeleteValuationTemplate,
    postValuationResult: state.postValuationResult,
    postValuationTemplateSave: state.postValuationTemplateSave,
    postValuationTemplateAdd: state.postValuationTemplateAdd,
    // screener
    getScreenerCustom: state.getScreenerCustom,
    getScreenerMetrics: state.getScreenerMetrics,
    getLoadPresetScreener: state.getLoadPresetScreener,
    postScreenerListResult: state.getLoadPresetScreener,
    // TODO: Remove this lines after layout feature has been implemented
    currentScreenerData: state.currentScreenerData,
  }),
  (state) => state,
);

// Reducer
export const reducers = {
  // TODO: Remove this lines after layout feature has been implemented
  setCurrentScreenerData: (state, action) => {
    state.currentScreenerData = { ...state.currentScreenerData, ...action.payload };
  },
};

// effects
export const effects = {
  // getValuationTemplate
  getValuationTemplate: createAsyncThunk<GetValuationTemplateResponse>(
    actionType.GET_VALUATION_TEMPLATE,
    async () => {
      try {
        const response = await valuationAPI.getValuationTemplate();

        if (!response.data) {
          throw new Error('Error when get list valuation template');
        }

        const { data, error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { data, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),

  // getValuationMetrics
  getValuationMetrics: createAsyncThunk<GetValuationMetricsResponse>(
    actionType.GET_VALUATION_METRICS,
    async () => {
      try {
        const response = await valuationAPI.getValuationMetrics();

        if (!response.data) {
          throw new Error('Error when get list valuation metrics');
        }

        const { data, error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { data, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),

  // getValuationTemplateLoad
  getValuationTemplateLoad: createAsyncThunk<
    GetValuationTemplateLoadResponse,
    number
  >(actionType.GET_VALUATION_TEMPLATE_LOAD, async (templateId) => {
    try {
      const response = await valuationAPI.getValuationTemplateLoad(templateId);

      if (!response.data) {
        throw new Error('Error when get detail valuation template');
      }

      const { data, error, message } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data, message };
    } catch (error) {
      return Promise.reject(error);
    }
  }),

  // getValuationMetricsDefault
  getValuationMetricsDefault: createAsyncThunk<
    GetValuationMetricsDefaultResponse,
    { symbol: string }
  >(actionType.GET_VALUATION_METRICS_DEFAULT, async (queryString) => {
    try {
      const response = await valuationAPI.getValuationMetricsDefault(
        queryString,
      );

      if (!response.data) {
        throw new Error('Error when get valuation metrics default');
      }

      const { data, error, message } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data, message };
    } catch (error) {
      return Promise.reject(error);
    }
  }),

  // postValuationResult
  postValuationResult: createAsyncThunk<PostValuationResultResponse, any>(
    actionType.POST_VALUATION_RESULT,
    async (requestBody) => {
      try {
        const response = await valuationAPI.postValuationResult(requestBody);

        if (!response.data) {
          throw new Error('Error when post valuation result');
        }

        const { data, error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { data, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),

  // postValuationTemplateSave
  postValuationTemplateSave: createAsyncThunk<
    PostValuationTemplateSaveResponse,
    { templateId: number; requestBody: any }
  >(
    actionType.POST_VALUATION_TEMPLATE_SAVE,
    async ({ templateId, requestBody }) => {
      try {
        const response = await valuationAPI.postValuationTemplateSave(
          templateId,
          requestBody,
        );

        if (!response.data) {
          throw new Error('Error when save valuation template');
        }

        const { data, error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { data, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),

  // postValuationTemplateAdd
  postValuationTemplateAdd: createAsyncThunk<
    PostValuationTemplateAddResponse,
    any
  >(actionType.POST_VALUATION_TEMPLATE_ADD, async (requestBody) => {
    try {
      const response = await valuationAPI.postValuationTemplateAdd(requestBody);

      if (!response.data) {
        throw new Error('Error when add valuation template');
      }

      const { data, error, message } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data, message };
    } catch (error) {
      return Promise.reject(error);
    }
  }),

  // screener

  // getScreenerCustom
  getScreenerCustom: createAsyncThunk<GetScreenerCustomResponse>(
    actionType.GET_SCREENER_CUSTOM,
    async () => {
      try {
        const response = await screenerAPI.getScreenerCustom();

        if (!response.data) {
          throw new Error('Error when get list screener custom');
        }

        const { data, error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { data, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),

  // getScreenerMetrics
  getScreenerMetrics: createAsyncThunk<GetScreenerMetricsResponse>(
    actionType.GET_SCREENER_METRICS,
    async () => {
      try {
        const response = await screenerAPI.getScreenerMetrics();

        if (!response.data) {
          throw new Error('Error when get list screener metrics');
        }

        const { data, error, message } = response.data;

        if (error) {
          throw new Error(error);
        }

        return { data, message };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  ),

  // getLoadPresetScreener
  getLoadPresetScreener: createAsyncThunk<
    GetLoadPresetScreenerResponse,
    { presetId: number; type: ScreenerTemplateType }
  >(actionType.GET_LOAD_PRESET_SCREENER, async ({ presetId, type }) => {
    try {
      const response = await screenerAPI.getLoadPresetScreener(presetId, type);

      if (!response.data) {
        throw new Error('Error when get load preset screener');
      }

      const { data, error, message } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data, message };
    } catch (error) {
      return Promise.reject(error);
    }
  }),

  // postDeleteValuationTemplate
  postDeleteValuationTemplate: createAsyncThunk<
    PostDeleteValuationTemplateResponse,
    number
  >(actionType.POST_DELETE_VALUATION_TEMPLATE, async (valuationId) => {
    try {
      const response = await valuationAPI.postDeleteValuationTemplate(
        valuationId,
      );

      if (!response.data) {
        throw new Error('Error when delete valuation template');
      }

      const { data, error, message } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data, message };
    } catch (error) {
      return Promise.reject(error);
    }
  }),

  // postScreenerListResult
  postScreenerListResult: createAsyncThunk<
    PostScreenerListResultResponse,
    IScreenerSaveOrRunPayload
  >(actionType.POST_SCREENER_LIST_RESULT, async (requestBody) => {
    try {
      const response = await screenerAPI.postScreenerListResult(requestBody);

      if (!response.data) {
        throw new Error('Error when post screener list result');
      }

      const { data, error, message } = response.data;

      if (error) {
        throw new Error(error);
      }

      return { data, message };
    } catch (error) {
      return Promise.reject(error);
    }
  }),
};

// Extra / Async Reducer
const extraReducers = (builder: ActionReducerMapBuilder<any>) => {
  // getValuationTemplate
  builder
    // Extra reducers for fetch suggestions
    .addCase(effects.getValuationTemplate.pending, (state) => {
      state.getValuationTemplate.error = null;
      state.getValuationTemplate.isLoading = true;
    })
    .addCase(effects.getValuationTemplate.fulfilled, (state, action) => {
      state.getValuationTemplate.data = action.payload.data;
      state.getValuationTemplate.error = null;
      state.getValuationTemplate.isLoading = false;
      state.getValuationTemplate.message = action.payload.message;
    })
    .addCase(effects.getValuationTemplate.rejected, (state, action) => {
      state.getValuationTemplate.error = action.error;
      state.getValuationTemplate.isLoading = false;
    })
    .addCase(effects.getValuationMetrics.pending, (state) => {
      state.getValuationMetrics.error = null;
      state.getValuationMetrics.isLoading = true;
    })
    .addCase(effects.getValuationMetrics.fulfilled, (state, action) => {
      state.getValuationMetrics.data = action.payload.data;
      state.getValuationMetrics.error = null;
      state.getValuationMetrics.isLoading = false;
      state.getValuationMetrics.message = action.payload.message;
    })
    .addCase(effects.getValuationMetrics.rejected, (state, action) => {
      state.getValuationMetrics.error = action.error;
      state.getValuationMetrics.isLoading = false;
    })

    .addCase(effects.getValuationTemplateLoad.pending, (state) => {
      state.getValuationTemplateLoad.error = null;
      state.getValuationTemplateLoad.isLoading = true;
    })
    .addCase(effects.getValuationTemplateLoad.fulfilled, (state, action) => {
      state.getValuationTemplateLoad.data = action.payload.data;
      state.getValuationTemplateLoad.error = null;
      state.getValuationTemplateLoad.isLoading = false;
      state.getValuationTemplateLoad.message = action.payload.message;
    })
    .addCase(effects.getValuationTemplateLoad.rejected, (state, action) => {
      state.getValuationTemplateLoad.error = action.error;
      state.getValuationTemplateLoad.isLoading = false;
    })

    .addCase(effects.getValuationMetricsDefault.pending, (state) => {
      state.getValuationMetricsDefault.error = null;
      state.getValuationMetricsDefault.isLoading = true;
    })
    .addCase(effects.getValuationMetricsDefault.fulfilled, (state, action) => {
      state.getValuationMetricsDefault.data = action.payload.data;
      state.getValuationMetricsDefault.error = null;
      state.getValuationMetricsDefault.isLoading = false;
      state.getValuationMetricsDefault.message = action.payload.message;
    })
    .addCase(effects.getValuationMetricsDefault.rejected, (state, action) => {
      state.getValuationMetricsDefault.error = action.error;
      state.getValuationMetricsDefault.isLoading = false;
    })

    .addCase(effects.getScreenerCustom.pending, (state) => {
      state.getScreenerCustom.error = null;
      state.getScreenerCustom.isLoading = true;
    })
    .addCase(effects.getScreenerCustom.fulfilled, (state, action) => {
      state.getScreenerCustom.data = action.payload.data;
      state.getScreenerCustom.error = null;
      state.getScreenerCustom.isLoading = false;
      state.getScreenerCustom.message = action.payload.message;
    })
    .addCase(effects.getScreenerCustom.rejected, (state, action) => {
      state.getScreenerCustom.error = action.error;
      state.getScreenerCustom.isLoading = false;
    })

    .addCase(effects.getScreenerMetrics.pending, (state) => {
      state.getScreenerMetrics.error = null;
      state.getScreenerMetrics.isLoading = true;
    })
    .addCase(effects.getScreenerMetrics.fulfilled, (state, action) => {
      state.getScreenerMetrics.data = action.payload.data;
      state.getScreenerMetrics.error = null;
      state.getScreenerMetrics.isLoading = false;
      state.getScreenerMetrics.message = action.payload.message;
    })
    .addCase(effects.getScreenerMetrics.rejected, (state, action) => {
      state.getScreenerMetrics.error = action.error;
      state.getScreenerMetrics.isLoading = false;
    })

    .addCase(effects.getLoadPresetScreener.pending, (state) => {
      state.getLoadPresetScreener.error = null;
      state.getLoadPresetScreener.isLoading = true;
    })
    .addCase(effects.getLoadPresetScreener.fulfilled, (state, action) => {
      state.getLoadPresetScreener.data = action.payload.data;
      state.getLoadPresetScreener.error = null;
      state.getLoadPresetScreener.isLoading = false;
      state.getLoadPresetScreener.message = action.payload.message;
      state.currentScreenerData.isDataLoaded = true;
    })
    .addCase(effects.getLoadPresetScreener.rejected, (state, action) => {
      state.getLoadPresetScreener.error = action.error;
      state.getLoadPresetScreener.isLoading = false;
    })

    .addCase(effects.postDeleteValuationTemplate.pending, (state) => {
      state.postDeleteValuationTemplate.error = null;
      state.postDeleteValuationTemplate.isLoading = true;
      state.postDeleteValuationTemplate.isSubmitted = false;
    })
    .addCase(effects.postDeleteValuationTemplate.fulfilled, (state, action) => {
      state.postDeleteValuationTemplate.data = action.payload.data;
      state.postDeleteValuationTemplate.error = null;
      state.postDeleteValuationTemplate.isLoading = false;
      state.postDeleteValuationTemplate.message = action.payload.message;
      state.postDeleteValuationTemplate.isSubmitted = true;
    })
    .addCase(effects.postDeleteValuationTemplate.rejected, (state, action) => {
      state.postDeleteValuationTemplate.error = action.error;
      state.postDeleteValuationTemplate.isLoading = false;
      state.postDeleteValuationTemplate.isSubmitted = false;
    })

    .addCase(effects.postScreenerListResult.pending, (state) => {
      state.postScreenerListResult.error = null;
      state.postScreenerListResult.isLoading = true;
    })
    .addCase(effects.postScreenerListResult.fulfilled, (state, action) => {
      state.postScreenerListResult.data = action.payload.data;
      state.getLoadPresetScreener.data = action.payload.data;
      state.postScreenerListResult.error = null;
      state.postScreenerListResult.isLoading = false;
      state.postScreenerListResult.message = action.payload.message;
    })
    .addCase(effects.postScreenerListResult.rejected, (state, action) => {
      state.postScreenerListResult.error = action.error;
      state.postScreenerListResult.isLoading = false;
    })

    .addCase(effects.postValuationResult.pending, (state) => {
      state.postValuationResult.error = null;
      state.postValuationResult.isLoading = true;
      state.postValuationResult.isSubmitted = false;
    })
    .addCase(effects.postValuationResult.fulfilled, (state, action) => {
      state.postValuationResult.data = action.payload.data;
      state.postValuationResult.error = null;
      state.postValuationResult.isLoading = false;
      state.postValuationResult.message = action.payload.message;
      state.postValuationResult.isSubmitted = true;
    })
    .addCase(effects.postValuationResult.rejected, (state, action) => {
      state.postValuationResult.error = action.error;
      state.postValuationResult.isLoading = false;
      state.postValuationResult.isSubmitted = false;
    })

    .addCase(effects.postValuationTemplateSave.pending, (state) => {
      state.postValuationTemplateSave.error = null;
      state.postValuationTemplateSave.isLoading = true;
      state.postValuationTemplateSave.isSubmitted = false;
    })
    .addCase(effects.postValuationTemplateSave.fulfilled, (state, action) => {
      state.postValuationTemplateSave.data = action.payload.data;
      state.postValuationTemplateSave.error = null;
      state.postValuationTemplateSave.isLoading = false;
      state.postValuationTemplateSave.message = action.payload.message;
      state.postValuationTemplateSave.isSubmitted = true;
    })
    .addCase(effects.postValuationTemplateSave.rejected, (state, action) => {
      state.postValuationTemplateSave.error = action.error;
      state.postValuationTemplateSave.isLoading = false;
      state.postValuationTemplateSave.isSubmitted = false;
    })

    .addCase(effects.postValuationTemplateAdd.pending, (state) => {
      state.postValuationTemplateAdd.error = null;
      state.postValuationTemplateAdd.isLoading = true;
      state.postValuationTemplateAdd.isSubmitted = false;
    })
    .addCase(effects.postValuationTemplateAdd.fulfilled, (state, action) => {
      state.postValuationTemplateAdd.data = action.payload.data;
      state.postValuationTemplateAdd.error = null;
      state.postValuationTemplateAdd.isLoading = false;
      state.postValuationTemplateAdd.message = action.payload.message;
      state.postValuationTemplateAdd.isSubmitted = true;
    })
    .addCase(effects.postValuationTemplateAdd.rejected, (state, action) => {
      state.postValuationTemplateAdd.error = action.error;
      state.postValuationTemplateAdd.isLoading = false;
      state.postValuationTemplateAdd.isSubmitted = false;
    });
};

// Slice
const valuationSlice = createSlice({
  name: 'valuation',
  initialState,
  reducers,
  extraReducers,
});

export default valuationSlice;
