import create from 'zustand/vanilla';

interface State {
  errorCode: string | number;
  errorMessage: string;
  errorType: string;
}

interface Action {
  setErrorCode: (
    code: string | number,
    message: string | null,
    type: string | null,
  ) => void;
  resetErrorCode: () => void;
}

const initialState = {
  errorCode: 0,
  errorMessage: '',
  errorType: '',
};

const tradingStore = create<State & Action>((set) => ({
  ...initialState,
  setErrorCode: (code, message = '', type = '') => {
    set({ errorCode: code, errorMessage: message, errorType: type });
  },
  resetErrorCode: () => {
    set(initialState);
  },
}));

export default tradingStore;
