import { assetPrefix } from 'constants/app';
import PropTypes from 'prop-types';

const Image = ({ src, alt = '', ...rest }) => {
  let source = src || '';

  const isBlob = source.startsWith('blob:');
  const isBase64 = source.startsWith('data:');
  const isUrl = source.startsWith('http') || source.startsWith('//');

  if (source && !isUrl && !isBlob && !isBase64) {
    const separator = source.startsWith('/') ? '' : '/';
    source = `${assetPrefix}${separator}${source}`;
  }

  return <img src={source} alt={alt} {...rest} />;
};

Image.defaultProps = {
  alt: '',
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default Image;
