import { IconWrapper as UIIconWrapper } from '@deprecated-ui/common/Iconmoon';
import color from '@deprecated-ui/_style/color';
import styled from 'styled-components';

const IconWrapper = styled(UIIconWrapper)`
  background: ${color.primary};
  border-radius: 50%;
  margin-right: 10px;
`;

// eslint-disable-next-line import/prefer-default-export
export { IconWrapper };
