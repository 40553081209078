import axios from 'axios';
import authLocalStorage from 'utils/auth';
import { API_EXODUS_URL } from 'constants/api';
import {
  GetRefreshTokenConfig,
  GetAccessTokenData,
  RefreshTokenPromise,
  RefreshRequestConfig,
} from './type';

export const initialRefreshTokenConfig: RefreshRequestConfig = {
  refreshingTokenStatus: 'initial',
  refreshAndRetryQueue: [],
};

export const getRefreshTokenConfigFn: GetRefreshTokenConfig = (
  initNewConfig = false,
): RefreshRequestConfig => {
  if (typeof window !== 'undefined') {
    if (initNewConfig) {
      window.refreshRequestConfig = {
        refreshingTokenStatus: 'initial',
        refreshAndRetryQueue: [],
      };
      return window.refreshRequestConfig;
    }

    if (typeof window.refreshRequestConfig !== 'undefined') {
      return window.refreshRequestConfig;
    }
    return undefined;
  }
  return initialRefreshTokenConfig;
};

export const getAccessTokenDataFn: GetAccessTokenData = () => {
  const parsedToken = authLocalStorage.getToken();
  const accessToken = parsedToken?.accessToken?.token || '';
  const accessTokenExpiry = parsedToken?.accessToken?.expiry || 0;
  const refreshToken = parsedToken?.refresh?.token || '';
  const refreshTokenExpiry = parsedToken?.refresh?.expiry || 0;
  return {
    accessToken,
    accessTokenExpiry,
    refreshToken,
    refreshTokenExpiry,
  };
};

export const refreshExodusToken: RefreshTokenPromise = async () => {
  const parsedToken = authLocalStorage.getToken();
  const refreshToken = parsedToken?.refresh?.token;
  const response = await axios.post(`${API_EXODUS_URL}/login/refresh`, null, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
  if (!response || !response.data || !response.data.data) {
    throw new Error('Failed Refresh Token');
  }
  const { data: newAccessTokenData } = response.data;
  const parsedAccessExpired = new Date(
    newAccessTokenData.access.expired_at,
  ).getTime();
  const parsedRefreshExpired = new Date(
    newAccessTokenData.refresh.expired_at,
  ).getTime();
  authLocalStorage.saveToken({
    accessToken: newAccessTokenData.access.token,
    accessTokenExp: parsedAccessExpired,
    refreshToken: newAccessTokenData.refresh.token,
    refreshTokenExp: parsedRefreshExpired,
    accessUser: authLocalStorage.getUserAccess(),
  });
  return newAccessTokenData.access.token;
};
