import handleErrorMessageAPI from 'global/AlertErrorMessage';
import ERROR_MESSAGE from 'constants/errorMessage';
import { dispatchSetErrorCode } from './utils';

/**
 * handleDefault
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleDefault = (errorType, message) => {
  if (errorType === ERROR_MESSAGE.PAYWALL_NOSUBSCRIPTION) return;
  if (errorType === ERROR_MESSAGE.FORBIDDEN) return;

  const errMessageDefault = message;
  handleErrorMessageAPI(errMessageDefault, ERROR_MESSAGE.ALERT_RED);
};

/**
 * handleMaintenance
 * web legacy: app\lib\Api.js line 184
 * @param {*} errorType | MAINTENANCE | ERROR_GATEWAY
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleMaintenance = (errorType, message) => {
  if (errorType === 'MAINTENANCE' && message) {
    dispatchSetErrorCode(503, message);
  }
};

/**
 * handleUnAuthorized
 * web legacy: app\lib\Api.js line 192
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleInvalidParameter = (_, message) => {
  handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
};

/**
 * handleInternalServerError
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
// eslint-disable-next-line no-unused-vars
const handleInternalServerError = (_, message) => {
  dispatchSetErrorCode(500);
};

/**
 * handleNotFound
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleNotFound = (_, message) => {
  handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
};

/**
 * handleNotSubscription
 * web legacy: app\lib\Api.js line 238
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleNotSubscription = () => {
  // TODO: url
};

/**
 * @deprecated 401 Unathorized is directly handled from API instance
 * handleUnAuthorized
 * @param {Promise} originalRequest
 * @return Promise originalRequest with new Authorization header or error rejection
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleUnAuthorized = async (originalRequest) => {};

export default {
  handleDefault,
  handleMaintenance,
  handleInvalidParameter,
  handleNotFound,
  handleNotSubscription,
  handleUnAuthorized,
  handleInternalServerError,
};
