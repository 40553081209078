/* eslint-disable react/jsx-props-no-spreading */
import styled from "styled-components";
import { Select as BaseSelect } from "antd";

import colors from "../_style/color";
import handleSpaces, { spacingProps } from "../utils/handleSpaces";

const Select = styled(BaseSelect).attrs((props) => ({
  width: props.width,
  ...spacingProps(props),
}))`
  width: ${({ width }) => width};
  ${handleSpaces}

  .ant-select-selector {
    border-color: ${colors.gainsboro} !important;
    box-shadow: none !important;
    height: inherit !important;
  }
`;

export default Select;
