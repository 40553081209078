import styled, { css } from "styled-components";
import { Button as BaseButton } from "antd";
import handleSpaces, { spacingProps } from "../utils/handleSpaces";

import color from "../_style/color";

const buttonTheme = {
  primary: css`
    background-color: ${color.primary};
    border: 1px solid ${color.primary};
    color: ${color.light};
  `,
  "outline-primary": css`
    border: 1px solid ${color.primary};
    color: ${color.primary};
  `,
  secondary: css`
    background-color: ${color.sunsetOrange};
    border: 1px solid ${color.sunsetOrange};
    color: ${color.light};
  `,
  facebook: css`
    background-color: ${color.secondary};
    border: 1px solid ${color.secondary};
    color: ${color.light};
  `,
  trading: css`
    background-color: ${color.trading};
    border: 1px solid ${color.trading};
    color: ${color.light};
  `,
  default: css`
    background-color: ${color.light};
    border: 1px solid ${color.gainsboro};
    color: inherit;
  `,
  borderless: css`
    background: none;
    border: none;
    box-shadow: none;
  `,
  muted: css`
    background-color: ${color.suvaGrey};
    border: unset;
    color: ${color.light};
    cursor: not-allowed;
  `,
};

const Button = styled(BaseButton).attrs((props) => ({
  type: props.type || "default",
  margin: props.margin || "unset",
  height: props.height || "auto",
  radius: props.radius || "2px",
  isHoverable: props.isHoverable || false,
  width: props.width,
  fontSize: props.fontSize || "14px",
  ...spacingProps(props),
}))`
  font-family: "ProximaNovaRg";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ radius }) => radius};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  font-size: ${({ fontSize }) => fontSize};

  ${({ color: key }) =>
    color &&
    css`
      color: ${color[key] || "inherit"};
      :hover {
        color: ${color[key] || "inherit"};
      }
    `}
  ${({ type }) => buttonTheme[type]};

  :focus,
  :hover {
    ${({ type }) => buttonTheme[type]}
  }

  [disabled] {
    color: ${color.light} !important;
    background-color: ${color.midgray} !important;
  }

  ::after {
    content: unset;
  }

  ${({ isHoverable }) =>
    isHoverable &&
    css`
      :hover {
        transition: 0.2s ease-in;
        cursor: pointer;
        background-color: ${color.primary};
        color: ${color.light};
        border: 1px solid ${color.gainsboro};
      }
    `}

  ${(props) =>
    css`
      ${handleSpaces(props)}
    `}

    ::after {
    content: unset;
  }
`;

export default Button;
