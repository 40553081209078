import handle from './handler';

const {
  handleDefault,
  handleMaintenance,
  handleInvalidParameter,
  handleNotFound,
  handleNotSubscription,
  handleUnAuthorized,
  handleInternalServerError,
} = handle;

/**
 * Exodus API error message handler
 * @param {number} status - HTTP Response status | required
 * @param {string} errorType - Error types | required
 * @param {string} message - Error message | required
 */
const APIExodusErrorHandler = (status, errorType, message) => {
  const listErrorResponse = {
    DEFAULT: handleDefault,
    400: handleInvalidParameter,
    401: handleUnAuthorized,
    402: handleNotSubscription,
    404: handleNotFound,
    500: handleInternalServerError,
    503: handleMaintenance,
  };

  const response = listErrorResponse[status] || listErrorResponse.DEFAULT;

  return response(errorType, message);
};
export default { APIExodusErrorHandler };
