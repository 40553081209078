import { Popover } from "antd";
import styled from "styled-components";
import color from "../_style/color";
import Flex from "./Flex";

const PopoverContentWrapper = styled.div.attrs((props) => ({ padding: props.padding || 0 }))`
  height: 100%;
  max-height: 400px;
  border: 1px solid ${color.gainsboro};
  border-radius: 2px;
  padding: ${({ padding }) => padding};
`;

const PopoverTitleWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${color.gainsboro};
  height: 40px;
  padding: 0 16px;
`;

const PopoverContent = styled.div`
  max-height: 360px;
  overflow-y: auto;
`;

export { PopoverTitleWrapper, PopoverContentWrapper, PopoverContent };
export default Popover;
