import styled, { css } from "styled-components";
import { Input as InputDefault } from "antd";
import color from "../_style/color";
import device from "../_style/device";
import handleSpaces, { spacingProps } from "../utils/handleSpaces";

const type = {
  basic: css``,
  withdraw: css`
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    height: 35px;
    border: 1px solid ${color.gainsboro};
    @media ${device.laptop} {
      width: 300px;
    }
  `,
};
const Input = styled(InputDefault).attrs((props) => ({
  height: props.height || "auto",
  width: props.width || "auto",
  margin: props.margin || "0",
  padding: props.padding || "5px",
  round: props.round || true,
  type: props.type || "basic",
  ...spacingProps(props),
}))`
  font-family: "ProximaNovaRg";
  height: ${({ height }) => height};
  width: ${({ width }) => width} !important;
  margin: ${({ margin }) => margin};
  border: 1px solid ${color.gainsboro};
  border-radius: ${({ round }) => (round ? "2px" : "unset")};

  .ant-input::placeholder {
    color: ${color.dimgray};
    font-size: 13px;
  }

  :hover,
  :focus,
  :focus-within {
    border-color: ${color.gainsboro};
    outline-color: transparent;
    box-shadow: none;
  }

  ${({ type: typeKey }) => type[typeKey]}

  ${(props) =>
    css`
      ${handleSpaces(props)}
    `}
`;

Input.TextArea = styled(InputDefault.TextArea).attrs((props) => ({
  height: props.height || "auto",
  width: props.width || "100%",
  margin: props.margin || "0",
  ...spacingProps(props),
}))`
  font-family: "ProximaNovaRg";
  height: ${({ height }) => height};
  width: ${({ width }) => width} !important;
  margin: ${({ margin }) => margin};
  border-color: ${color.gainsboro};

  .ant-input::placeholder {
    color: ${color.dimgray};
    font-size: 13px;
  }

  :hover,
  :focus,
  :focus-within {
    border-color: ${color.gainsboro};
    box-shadow: none;
  }

  ${(props) =>
    css`
      ${handleSpaces(props)}
    `}
`;
export default Input;
